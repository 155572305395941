import React from "react";
import styled from "styled-components";
import { themes } from "utils/themes";
import { fontstack } from "utils/fontstack";
import { type } from "utils/type";
import { Arrow } from "components/Iconography/Iconography";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ArrowWrapper = styled.div`
  width: 20px;
  transform: rotate(90deg);
`;

const Text = styled.div`
  ${fontstack.secondary}
  ${type("subheaderB")}
  ${themes("text")}
  text-transform: uppercase;
  margin: 14px 0 0;
`;

const ScrollDown = ({ theme, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <ArrowWrapper>
        <Arrow theme={theme} />
      </ArrowWrapper>
      <Text theme={theme}>SCROLL DOWN</Text>
    </Wrapper>
  );
};

export default ScrollDown;
