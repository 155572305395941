import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import Grid from "components/Grid/Grid";
import { Header, Title, CTA, Semi } from "components/Module/Module";
import { SectionMargin } from "components/Section/Section";
import { Square, Portrait } from "modules/EditorialSummary/EditorialSummary";
import { getBlogUrl } from "lib/helpers";
import { media } from "utils/mediaQuery";
import "swiper/css";

const SquareCol = styled.div`
  grid-column: span 6;
`;

const PortraitCol = styled.div`
  grid-column: span 3;
`;

const DesktopGrid = styled(Grid)`
  display: none;
  ${media.large` 
    display: grid;
  `}
`;

const MobileCarousel = styled.div`
  display: block;
  overflow: hidden;
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);

  ${media.large` 
    display: none;
  `}
`;

const CustomSection = styled(SectionMargin)`
  padding: 0;
  ${media.large` 
    padding: 0 50px;
  `}
`;

const FeaturedEditorials = ({ nodes }) => {
  return (
    <SectionMargin small>
      <Header>
        <Title>the new moon <Semi>library</Semi></Title>
        <CTA>
          <Link to="/library">see all editorials</Link>
        </CTA>
      </Header>
      <MobileCarousel>
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={15}
          style={{ overflow: "visible"}}
        >
          {nodes &&
            nodes.map((node, i) => {
              return (
                <SwiperSlide key={i} style={{ width: "80%", height: "auto" }}>
                  <Link to={getBlogUrl(node.publishedAt, node.slug.current)}>
                    <Portrait
                      title={node.title}
                      displayTitle={node._rawDisplayTitle}
                      category={node.categories[0].title || null}
                      image={node.portraitImage.asset}
                    />
                  </Link>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </MobileCarousel>
      <DesktopGrid>
        {nodes &&
          nodes.map((node, i) => {
            if (i === 0) {
              return (
                <SquareCol>
                  <Link to={getBlogUrl(node.publishedAt, node.slug.current)}>
                    <Square
                      title={node.title}
                      displayTitle={node._rawDisplayTitle}
                      category={node.categories[0].title || null}
                      image={node.mainImage.asset}
                    />
                  </Link>
                </SquareCol>
              );
            } else {
              return (
                <PortraitCol>
                  <Link to={getBlogUrl(node.publishedAt, node.slug.current)}>
                    <Portrait
                      title={node.title}
                      displayTitle={node._rawDisplayTitle}
                      category={node.categories[0].title || null}
                      image={node.portraitImage.asset}
                    />
                  </Link>
                </PortraitCol>
              );
            }
          })}
      </DesktopGrid>
    </SectionMargin>
  );
};

export default FeaturedEditorials;
