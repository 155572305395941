import React from "react";
import styled from "styled-components";
import PortableText from "components/portableText";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Parallax from "components/Parallax/Parallax";
import { Section } from "components/Section/Section";
import Grid from "components/Grid/Grid";
import ScrollDown from "components/ScrollDown/ScrollDown";
import { themes } from "utils/themes";
import { media } from "utils/mediaQuery";
import { fontstack } from "utils/fontstack";
import { type } from "utils/type";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  display: flex;
  align-items: flex-end;
  box-sizing: border-box;
  padding-bottom: 65px;

  ${media.large` 
    height: auto;
    display: block;
    padding-bottom: 0;
  `}
`;

const ContentCol = styled.div`
  grid-column: 1 / span 6;
  ${themes("text")}
`;

const Title = styled.div`
  ${fontstack.primary}
  ${type("titleA")}
  margin: 170px 0 40px;

  ${media.large`
    ${type("headlineA")}
  `}
  p {
    margin: 0;
  }
`;

const SubTitle = styled.div`
  ${fontstack.secondary}
  ${type("subheaderB")}
  margin: 0;
  text-transform: uppercase;
  text-decoration: underline;
`;

const ScrollDownWrapper = styled.div`
  position: absolute;
  bottom: 45px;
  width: 100%;
  z-index: 2;
  display: none;

  ${media.large`
    display: block;
  `}
`;

const RawSrcImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
`;

const ImageWrapper = styled(Parallax)`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Image = styled(GatsbyImage)`
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

const HeroA = ({ theme, rawSrc, image, alt, title, displayTitle, subtitle, ...rest }) => {
  const imageObject = image ? getImage(image) : null;
  return (
    <Wrapper {...rest}>
      <Content>
        <Section>
          <Grid>
            <ContentCol theme={theme}>
              <Title>
                {displayTitle ? (
                  <PortableText blocks={displayTitle} />
                ) : title ? (
                  title
                ) : null}
              </Title>
              <SubTitle>{subtitle}</SubTitle>
            </ContentCol>
          </Grid>
        </Section>
      </Content>
      <ScrollDownWrapper>
        <ScrollDown theme={theme} />
      </ScrollDownWrapper>
      <ImageWrapper>
        {imageObject && <Image image={imageObject} objectFit="cover" alt={alt} />}
        {rawSrc && <RawSrcImage src={rawSrc} />}
      </ImageWrapper>
    </Wrapper>
  );
};

export default HeroA;
