import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Grid from "components/Grid/Grid";
import PortableText from "components/portableText";
import { themes } from "utils/themes";
import { fontstack } from "utils/fontstack";
import { type } from "utils/type";
import { Arrow } from "components/Iconography/Iconography";
import { media } from "utils/mediaQuery";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;

  :after {
    pointer-events: none;
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    height: 300px;
    background: linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 85%, rgba(0,0,0,0) 100%);
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 1;
  ${themes("text")}
  width: 100%;
  pointer-events: none;
`;

const Category = styled.h5`
  ${fontstack.secondary}
  ${type("subheaderC")}
  text-transform: uppercase;
  margin: 0;

  ${media.large` 
    ${type("subheaderB")}
  `}
`;

const CustomGrid = styled(Grid)`
  ${media.large` 
    display: block;
  `}
`;

const ColLeft = styled.div`
  grid-column: span 4;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${media.large` 
    display: block;
  `}
`;

const ColRight = styled.div`
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${media.large` 
    display: block;
  `}
`;

const Title = styled.h2`
  ${fontstack.primary}
  ${type("body2")}
  margin: 0;

  ${media.large` 
    ${type("titleB")}
    margin: 0 0 48px;
  `}

  p {
    margin: 0;
  }
`;

const ImageWrapper = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

const Image = styled(GatsbyImage)`
  position: relative;
  width: 100%;
  height: 100%;
`;

const DateLine = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Date = styled.div`
  ${fontstack.secondary}
  ${type("subheaderB")}
`;

const ArrowCustom = styled(Arrow)`
  width: 16px;
  display: none;

  ${media.large` 
    display: block;
  `}
`;

const EventSummary = ({ theme, image, displayTitle, title, category, start, end }) => {
  const imageObject = image ? getImage(image) : null;

  const animationProps = {
    whileHover: {
      scale: 1.04,
    },
    transition: {
      duration: 0.4,
    },
  };

  return (
    <Wrapper>
      <Content theme={theme}>
        <CustomGrid>
          <ColLeft>
            <Category>{category}</Category>
            <Title>
              {displayTitle ? (
                <PortableText blocks={displayTitle} />
              ) : title ? (
                title
              ) : null}
            </Title>
          </ColLeft>
          <ColRight>
            <DateLine>
              <Date>
                {start}
                {end && ` - ${end}`}
              </Date>
              <ArrowCustom theme={theme} />
            </DateLine>
          </ColRight>
        </CustomGrid>
      </Content>
      <ImageWrapper {...animationProps}>
        {imageObject && (
          <Image
            image={imageObject}
            objectFit="cover"
            placeholder="blurred"
            style={{ position: "absolute" }}
          />
        )}
      </ImageWrapper>
    </Wrapper>
  )
}

export default EventSummary;
