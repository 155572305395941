import React from "react";
import styled from "styled-components";
import Parallax from "components/Parallax/Parallax";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const VideoTag = styled.video`
  position: relative;
  width: 100%;
`;

const FullBleedB = ({ src, poster, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <Parallax>
        <VideoTag playsInline autoPlay muted loop poster={poster}>
          <source src={src} type="video/mp4" />
          Your browser does not support the video tag.
        </VideoTag>
      </Parallax>
    </Wrapper>
  );
};

export default FullBleedB;
