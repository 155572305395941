import React from "react";
import styled from "styled-components";
import { SectionMargin } from "components/Section/Section";
import { media } from "utils/mediaQuery";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin-left: 50%;
  margin-bottom: 25px;
  transform: translate(-50%, 0);

  ${media.large` 
    flex-direction: row;
    margin-left: 0;
    transform: translate(0,0);
    width: auto;
    margin-bottom: 0;
    
    & > * {
    width: 50%;
    }
  `}
`;

const NewsHappeningsCombo = ({ children }) => {
  return (
    <SectionMargin small mobilebottom>
      <Wrapper>{children}</Wrapper>
    </SectionMargin>
  );
};

export default NewsHappeningsCombo;
