import React from "react";
import { graphql } from "gatsby";
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from "../lib/helpers";
import styled from "styled-components";
import BlogPostPreviewList from "../components/blog-post-preview-list";
import { SiteContext } from "context/site-context";
import HeroCarousel from "modules/HeroCarousel/HeroCarousel";
import FeaturedEditorials from "modules/FeaturedEditorials/FeaturedEditorials";
import PresentsVideo from "modules/PresentsVideo/PresentsVideo";
import FeaturedNews from "modules/FeaturedNews/FeaturedNews";
import FeaturedHappenings from "modules/FeaturedHappenings/FeaturedHappenings";
import NewsHappeningsCombo from "modules/NewsHappeningsCombo/NewsHappeningsCombo";
import FullBleedA from "modules/FullBleedA/FullBleedA";
import TextForm from "modules/TextForm/TextForm";
import ContactForm from "modules/TextForm/ContactForm";
import { SectionMargin } from "components/Section/Section";
import { moduleSelector } from "modules/Editorial/pageBuilder";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { media } from "utils/mediaQuery";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    carousel: allSanityHome {
      edges {
        node {
          heroCarousel {
            ... on SanityPost {
              _rawDisplayTitle
              _type
              id
              title
              slug {
                current
              }
              publishedAt
              featuredImage {
                desktopFeatured {
                  alt
                  caption
                  theme
                  asset {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
                mobileFeatured {
                  alt
                  caption
                  theme
                  asset {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
            }
            ... on SanityHeroCarouselSlide {
              _key
              _type
              url
              title
              featuredImage {
                desktopFeatured {
                  alt
                  theme
                  asset {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
                mobileFeatured {
                  alt
                  theme
                  asset {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
              callAction
            }
          }
        }
      }
    }
    editorials: allSanityHome {
      edges {
        node {
          editorial {
            mainImage {
              alt
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            portraitImage {
              alt
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            _rawDisplayTitle
            title
            publishedAt
            categories {
              title
            }
            slug {
              current
            }
          }
        }
      }
    }
    presents: allSanityHome {
      edges {
        node {
          presents {
            desktopVideo {
              asset {
                url
              }
            }
            mobileVideo {
              asset {
                url
              }
            }
          }
        }
      }
    }
    news: allSanityHome {
      edges {
        node {
          news {
            title
            _rawDisplayTitle
            type
            url
            date(formatString: "D MMM YYYY")
          }
        }
      }
    }
    featuredEvent: allSanityHome {
      edges {
        node {
          happenings {
            eventDate(formatString: "D MMM YYYY")
            eventEndDate(formatString: "D MMM YYYY")
            category {
              title
            }
            title
            _rawDisplayTitle
            image {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
    fullBleedA: allSanityHome {
      edges {
        node {
          fullBleedA {
            desktopFullBleedA {
              theme
              text {
                label
                url
              }
              alt
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            mobileFullBleedA {
              theme
              text {
                label
                url
              }
              alt
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
    contact: allSanityHome {
      edges {
        node {
          contact {
            _rawContent
          }
        }
      }
    }
    storyImage: allSanityHome {
      edges {
        node {
          storyImage {
            image {
              caption
              alt
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`;


const FullBleedADesktop = styled(FullBleedA)`
  display: none;
  ${media.large`
    display: grid;
  `}
`;

const FullBleedAMobile = styled(FullBleedA)`
  display: block;
  ${media.large`
    display: none;
  `}
`;

const IndexPage = (props) => {
  const { data, errors } = props;
  const { navTheme } = React.useContext(SiteContext);

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const carouselNodes = (data || {}).carousel
    ? data.carousel.edges[0].node.heroCarousel
    : [];
  const editorialsNodes = (data || {}).editorials
    ? data.editorials.edges[0].node.editorial
    : [];
  const presentsNode = (data || {}).presents
    ? data.presents.edges[0].node.presents
    : [];

  const newsNodes = (data || {}).news ? mapEdgesToNodes(data.news)[0].news : [];
  const featuredEventNode = (data || {}).featuredEvent
    ? mapEdgesToNodes(data.featuredEvent)[0].happenings
    : [];


  const fullBleedANode = (data || {}).fullBleedA
    ? data.fullBleedA.edges[0].node.fullBleedA
    : [];

  const contactNode = (data || {}).contact
    ? data.contact.edges[0].node.contact._rawContent
    : [];

  const storyImageNode = (data || {}).storyImage
    ? data.storyImage.edges[0].node.storyImage
    : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  console.log(fullBleedANode)

  return (
    <Layout theme={navTheme}>
      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
      />
      <HeroCarousel nodes={carouselNodes} />
      <FeaturedEditorials nodes={editorialsNodes} />
      <PresentsVideo node={presentsNode} />
      <TextForm content={contactNode}>
        <ContactForm />
      </TextForm>
      {moduleSelector["editorialImage3"](storyImageNode, { last: true })}
    </Layout>
  );
};

export default IndexPage;
