import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { SiteContext } from "context/site-context";
/* eslint-disable import/no-unresolved */
import { Swiper, SwiperSlide } from "swiper/react";
import HeroA from "modules/HeroA/HeroA";
import TriggerSolidNav from "components/TriggerSolidNav/TriggerSolidNav";
import { media } from "utils/mediaQuery";
import { getBlogUrl } from "lib/helpers";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

import { EffectFade, Pagination, Autoplay } from "swiper";

const Wrapper = styled.div`
  position: relative;
  height: calc(100vh - 100px);
  width: 100%;
  

  & .swiper-pagination {
    text-align: left;
    padding-left: 15px;
    ${media.large`
      padding-left: 0;
      text-align: center;
    `}
  }

  & .swiper-pagination-bullet {
    background: transparent;
    opacity: 1;
    border: ${(props) =>
      props.theme === "dark"
        ? "1px solid var(--brand-white)"
        : "1px solid var(--brand-black)"};
  }

  & .swiper-pagination-bullet-active {
    background: ${(props) =>
      props.theme === "dark" ? "var(--brand-white)" : "var(--brand-black)"};
  }
`;

const SwiperCustom = styled(Swiper)`
  height: 100%;
`;

const HeroADesktop = styled(HeroA)`
  display: none;
  ${media.large`
    display: block;
  `}
`;

const HeroAMobile = styled(HeroA)`
  display: block;
  ${media.large`
    display: none;
  `}
`;

const HeroCarousel = ({ nodes }) => {
  const { setNavTheme } = React.useContext(SiteContext);
  const [Active, setActive] = React.useState(0);
  const [Theme, setTheme] = React.useState();

  React.useEffect(() => {
    const theme = nodes[Active].featuredImage.desktopFeatured.theme;
    setTheme(theme);
    setNavTheme(theme);
  }, [Active]);

  return (
    <TriggerSolidNav>
      <Wrapper theme={Theme}>
        <SwiperCustom
          effect={"fade"}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          modules={[EffectFade, Pagination, Autoplay]}
          onActiveIndexChange={(swiper) => setActive(swiper.activeIndex)}
        >
          {nodes &&
            nodes.map((node, i) => {
              if (node._type === "post") {
                return (
                  <SwiperSlide key={i}>
                    <Link to={getBlogUrl(node.publishedAt, node.slug.current)}>
                      <HeroADesktop displayTitle={node._rawDisplayTitle} title={node.title} image={node.featuredImage.desktopFeatured.asset} subtitle={node.featuredImage.desktopFeatured.caption} alt={node.featuredImage.desktopFeatured.alt} theme={node.featuredImage.desktopFeatured.theme || "light"}/>
                      <HeroAMobile displayTitle={node._rawDisplayTitle} title={node.title} image={node.featuredImage.mobileFeatured.asset} subtitle={node.featuredImage.mobileFeatured.caption} alt={node.featuredImage.mobileFeatured.alt} theme={node.featuredImage.desktopFeatured.theme || "light"}/>
                    </Link>
                  </SwiperSlide>
                );
              } else if (node._type === "heroCarouselSlide") {
                return ( <SwiperSlide key={i}>
                  <Link to={node.url} target="_blank">
                    <HeroADesktop title={node.title} image={node.featuredImage.desktopFeatured.asset} subtitle={node.featuredImage.desktopFeatured.caption} alt={node.featuredImage.desktopFeatured.alt} theme={node.featuredImage.desktopFeatured.theme || "light"}/>
                    <HeroAMobile title={node.title} image={node.featuredImage.mobileFeatured.asset} subtitle={node.featuredImage.mobileFeatured.caption} alt={node.featuredImage.mobileFeatured.alt} theme={node.featuredImage.desktopFeatured.theme || "light"}/>
                  </Link>
                </SwiperSlide> )
              }
            })}
        </SwiperCustom>
      </Wrapper>
    </TriggerSolidNav>
  );
};

export default HeroCarousel;
