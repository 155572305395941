import React from "react";
import styled from "styled-components";
import PortableText from "components/portableText";
import { SectionModule } from "components/Section/Section";
import { themes } from "utils/themes";
import { fontstack } from "utils/fontstack";
import { type } from "utils/type";
import { media } from "utils/mediaQuery";

const BulletinWrapper = styled.ul`
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const BulletinList = ({children, ...rest }) => {
  return <BulletinWrapper {...rest}>{children}</BulletinWrapper>;
};

const BulletinItemWrapper = styled.li`
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid var(--brand-grey3);
`;

const Type = styled.h5`
  ${fontstack.secondary}
  ${type("subheaderD")}
  color: var(--brand-grey2);
  text-transform: uppercase;
  margin: 0;

  ${media.large` 
    ${type("subheaderB")}
  `}
`;

const Title = styled.h2`
  ${fontstack.primary}
  ${type("body1")}
  color: var(--brand-primary);
  margin: 0;
  transition: color 0.2s ease;

  &:hover {
    color: var(--brand-grey2);
  }

  & > p {
    margin: 0;
  }

  ${media.large` 
    ${type("titleB")}
  `}
`;

const Date = styled.div`
  ${fontstack.secondary}
  ${type("subheaderD")}
  color: var(--brand-grey2);
  text-transform: uppercase;
  margin: 0;

  ${media.large` 
    ${type("subheaderB")}
  `}
`;

const ItemSection = styled.div`
  margin: 40px 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  box-sizing: border-box;
  padding: 0 15px 0 15px;

  ${media.large` 
    padding: 0 30px 0 0;
  `}

  ${media.xxlarge` 
    margin: 70px 0 20px;
    padding: 0 40px 0 0;
  `}
`;

export const BulletinItem = ({
  type,
  title,
  displayTitle,
  date,
  url,
  ...rest
}) => {
  const ConditionalLink = ({ url, wrapper, children }) =>
    url ? wrapper(children) : <div>{children}</div>;

  return (
    <BulletinItemWrapper {...rest}>
      <ItemSection top>
        <ConditionalLink
          url={url}
          wrapper={(children) => (
            <a href={url} target="_blank" rel="noreferrer">
              {children}
            </a>
          )}
        >
          <Type>{type}</Type>
          <Title>
            {displayTitle ? (
              <PortableText blocks={displayTitle} />
            ) : title ? (
              title
            ) : null}
          </Title>
        </ConditionalLink>
        <div>
          <Date>{date}</Date>
        </div>
      </ItemSection>
    </BulletinItemWrapper>
  );
};
