import React from "react";
import styled from "styled-components";
import { useScrollerMotion } from "scroller-motion";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import { SiteContext } from "context/site-context";

const Inner = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
`;

const RefWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Parallax = ({ children, ...rest }) => {
  const { width } = React.useContext(SiteContext);
  const elementRef = React.useRef(null);
  const [elementStart, setElementStart] = React.useState(0);
  const [elementEnd, setElementEnd] = React.useState(0);
  const { scrollY } = useViewportScroll();
  const { x, y } = useScrollerMotion();
  const yValue = useTransform(y, [elementStart*-1, elementEnd*-1], ["0%", "60%"]);

  React.useLayoutEffect(() => {
    const element = elementRef.current;
    const onResize = () => {
      const box = element.getBoundingClientRect();
      const start = box.top + window.scrollY;
      const end = box.bottom + window.scrollY;
      setElementStart(start);
      setElementEnd(end);
    };

    onResize();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [elementRef]);

  return (
    <RefWrapper ref={elementRef} {...rest}>
      <Inner style={{ y: width <= 1080 ? "0" : yValue }}>{children}</Inner>
    </RefWrapper>
  );
};

export default Parallax;
