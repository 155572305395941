import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Parallax from "components/Parallax/Parallax";
import { fontstack } from "utils/fontstack";
import { type } from "utils/type";
import { themes } from "utils/themes";
import { media } from "utils/mediaQuery";
import { Arrow } from "components/Iconography/Iconography";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const Title = styled.h2`
  ${fontstack.primary}
  ${type("titleB")}
  ${themes("text")}
  display: flex;
  align-items: center;

  ${media.large` 
    ${type("headlineA")}
  `}
`;

const TestImage = styled.img`
  position: relative;
  width: 100%;
`;

const Image = styled(GatsbyImage)`
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

const ArrowWrapper = styled.div`
  width: 16px;
  margin: 0 0 0 7px;

  ${media.large` 
    width: 30px;
    margin: 0 0 0 25px;
  `}
`;

const FullBleedA = ({ title, theme, test, image, alt, ...rest }) => {
  const imageObject = image ? getImage(image) : null;
  return (
    <Wrapper {...rest}>
      <Content>
        <Title theme={theme}>
          {title}{" "}
          <ArrowWrapper>
            <Arrow theme={theme} />
          </ArrowWrapper>
        </Title>
      </Content>
      <Parallax>
        {imageObject && <Image image={imageObject} objectFit="cover" alt={alt} />}
        {test && <TestImage src={test} />}
      </Parallax>
    </Wrapper>
  );
};

export default FullBleedA;
