import React from "react";
import styled from "styled-components";
import { BoxHeader, CTA } from "components/Module/Module";
import EventSummary from "modules/EventSummary/EventSummary";
import { Link } from "gatsby";
import { media } from "utils/mediaQuery";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid var(--brand-grey2);
`;

const EventWrapper = styled(Link)`
  display: block;
  height: 400px;

  ${media.large` 
    height: 100%;
  `}
`;

const FeaturedHappenings = ({ node }) => {

  const category = node.category ? node.category.title : null;
  return (
    <Wrapper>
      <BoxHeader title="events & happenings" last>
          <CTA small>
            <Link to="/happenings">see all events</Link>
          </CTA>
      </BoxHeader>
      <EventWrapper to="/happenings">
        <EventSummary
          category={category}
          theme="dark"
          displayTitle={node._rawDisplayTitle}
          image={node.image.asset}
          start={node.eventDate}
          end={node.eventEndDate}
        />
      </EventWrapper>
    </Wrapper>
  );
};

export default FeaturedHappenings;
