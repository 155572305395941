import React from "react";
import styled from "styled-components";
import { Header, Title, Semi } from "components/Module/Module";
import { SectionMargin, PadSection } from "components/Section/Section";
import FullBleedB from "modules/FullBleedB/FullBleedB";
import { media } from "utils/mediaQuery";

const FullBleedBDesktop = styled(FullBleedB)`
  display: none;
  ${media.large`
    display: grid;
  `}
`;

const FullBleedBMobile = styled(FullBleedB)`
  display: block;
  ${media.large`
    display: none;
  `}
`;

const PresentsVideo = ({ node }) => {
  return (
    <SectionMargin small fullbleed>
      { /**
          <PadSection>
        <Header>
          <Title>the new moon <Semi>presents</Semi></Title>
        </Header>
        </PadSection>
       
       */}
      <FullBleedBMobile src={node.mobileVideo.asset.url} />
      <FullBleedBDesktop src={node.desktopVideo.asset.url} />
    </SectionMargin>
  );
};

export default PresentsVideo;
